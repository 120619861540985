import React from "react";
import { SkillTag } from "./styled.js";
const ProfileSkillTags = props => {
  const consultant = props.consultant;
  console.log(consultant);
  return (
    <>
      {consultant.expertise.split(",").map((expertiseItem, index) => (
        <SkillTag key={index}>{expertiseItem}</SkillTag>
      ))}
    </>
  );
};
export default ProfileSkillTags;
