import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import { AreaOfStrength } from "./styled.js";
const ProfileAreasOfStrength = props => {
  const consultant = props.consultant;
  return consultant.areasOfStrength.map((areaOfStrength, index) => (
    <Jumbotron key={areaOfStrength.id}>
      <div>
        <AreaOfStrength>{areaOfStrength.title}</AreaOfStrength>
        <div
          className="lead"
          dangerouslySetInnerHTML={{
            __html: areaOfStrength.strengthText
          }}
        />
      </div>
    </Jumbotron>
  ));
};
export default ProfileAreasOfStrength;
