import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IRLayout from "../components/Layout";
import Img from "gatsby-image";
import SkillTags from "../components/ProfileSkillTags/ProfileSkillTags";
import AreasOfStrength from "../components/ProfileAreasOfStrength/ProfileAreasOfStrength";
import Avatar from "../components/Avatar/Avatar";
class Consultant extends Component {
  constructor(props) {
    super(props);
    this.consultant = props.data.datoCmsConsultant;
  }
  render() {
    return (
      <IRLayout>
        <Helmet>
          <title>{this.consultant.consultantName} | Hourly Experts</title>
          <meta name="description" content={this.consultant.bio} />
        </Helmet>
        <div className="section white">
          <Container>
            <Row>
              <Col xs={12} md={3} style={{ textAlign: "left" }}>
                <div
                  style={{
                    textAlign: "left",
                    border: "0px solid #e0e0e0",
                    borderRadius: "5px",
                    padding: "10px 10px 10px 0px",
                    float: "left",
                    width: "100%",
                  }}
                >
                  <Avatar src={this.consultant.consultantImage.fixed} />
                  <div
                    style={{
                      float: "left",
                    }}
                  >
                    <h5
                      style={{
                        marginBottom: "0px",
                      }}
                    >
                      {this.consultant.consultantName}
                    </h5>
                    &pound;{this.consultant.hourlyRate} / hr
                  </div>
                </div>
              </Col>
              <Col xs={12} md={9}>
                <h3 style={{ marginBottom: "8px" }}>
                  {this.consultant.consultantName}
                </h3>
                <h5 style={{ marginBottom: "16px", fontWeight: "600" }}>
                  {/*Hourly consultant since June 2019 | */}£
                  {this.consultant.hourlyRate} per hour
                </h5>
                <p className="lead" style={{ fontSize: "1em" }}>
                  <Link to={`/consultants/${this.consultant.slug}/book`}>
                    Book a consultation with {this.consultant.firstName}
                  </Link>
                </p>
                <p className="lead" style={{ fontSize: "1em" }}>
                  <Link
                    to={`/consultants/${
                      this.consultant.slug
                    }/book-chemistry-session`}
                  >
                    Book a free chemistry session with{" "}
                    {this.consultant.firstName}
                  </Link>
                </p>
                <div
                  className=""
                  style={{ color: "rgba(0, 0, 0, 0.84)" }}
                  dangerouslySetInnerHTML={{
                    __html: this.consultant.bio,
                  }}
                />
                <SkillTags consultant={this.consultant} />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section">
          <Container>
            <Col xs={12} md={12}>
              <h5 style={{ paddingTop: "16px", marginBottom: "24px" }}>
                {this.consultant.firstName} can help with
              </h5>
              <AreasOfStrength consultant={this.consultant} />
            </Col>
          </Container>
        </div>
      </IRLayout>
    );
  }
}
export default Consultant;

export const query = graphql`
  query ConsultantQuery($slug: String!) {
    datoCmsConsultant(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slug
      consultantName
      firstName
      consultantImage {
        url
        fixed(width: 75) {
          ...GatsbyDatoCmsFixed
        }
      }
      bioSnippet
      bio
      expertise
      hourlyRate
      areasOfStrength {
        ... on DatoCmsStrength {
          id
          title
          strengthText
        }
      }
    }
  }
`;
