import styled from "styled-components";
export const SkillTag = styled.div`
  background: #f8f8f8;
  padding: 5px;
  margin: 3px;
  word-wrap: break-word;
  float: left;
  border-radius: 5px;
  color: #888;
  font-size: 0.8em;
`;
